import { Subject, NEVER, ReplaySubject, from, Observable, isObservable } from 'rxjs';
import { mergeAll, share, materialize, tap, map, startWith, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { coerceAllFactory } from '@rx-angular/cdk/coercing';

function toRxErrorNotification(error, value) {
    return {
        value,
        kind: "error" /* RxNotificationKind.Error */,
        hasValue: !!value || false,
        complete: false,
        error: error || true,
    };
}
function toRxSuspenseNotification(value) {
    return {
        value,
        kind: "suspense" /* RxNotificationKind.Suspense */,
        hasValue: !!value || false,
        complete: false,
        error: false,
    };
}
function toRxCompleteNotification(value) {
    return {
        value,
        kind: "complete" /* RxNotificationKind.Complete */,
        hasValue: !!value || false,
        complete: true,
        error: false,
    };
}

/**
 * @internal
 *
 * A factory function returning an object to handle the process of switching templates by Notification channel.
 * You can next a Observable of `RxNotification` multiple times and merge them into the Observable exposed under `trigger$`
 *
 */
function templateTriggerHandling() {
    const hotFlattened = coerceAllFactory(() => new Subject(), mergeAll());
    return {
        next(templateName) {
            hotFlattened.next(templateName);
        },
        trigger$: hotFlattened.values$.pipe(share()),
    };
}

function rxMaterialize() {
    return (o$) => o$.pipe(materialize(), tap(({ kind, error }) => {
        // As we dont want to just swallow errors we log them here
        if (kind === 'E') {
            console.error(error);
        }
    }), map(({ value, error, kind, hasValue }) => {
        const rxNotificationKind = notificationKindToRxNotificationKind(kind);
        return {
            value,
            hasValue,
            error,
            kind: rxNotificationKind,
            complete: rxNotificationKind === "complete" /* RxNotificationKind.Complete */,
        };
    }));
}
/**
 * @internal
 *
 * @description
 * This function is here to turn RxJS notification kind values into RxNotification kind names.
 * The main reason for the naming is the RxNotification kind values map directly to the default
 * template names (`suspense`, `next`, `error` `complete`) in the directives of the template package
 */
function notificationKindToRxNotificationKind(kind) {
    switch (kind) {
        case 'C':
            return "complete" /* RxNotificationKind.Complete */;
        case 'E':
            return "error" /* RxNotificationKind.Error */;
        case 'N':
        default:
            return "next" /* RxNotificationKind.Next */;
    }
}

/**
 * @description
 * Sends value and an initial `undefined` as value With a NEVER.
 * This is needed to render the suspense template and avoid completing (and render the complete template).
 * @param value
 */
const emitAndDontComplete = (value) => NEVER.pipe(startWith(value));
/**
 * This helper is responsible for turning a stream of materialized notifications
 * (next error, complete as object in the next stream) into an enriched version with an additional suspense
 * notification type.
 *
 * If a notification enters and is of type next we store tne value of `notification.next` as last value emitted.
 * This value is important in the template to show an e.g. error and also have access to the last emitted value of
 * next.
 * The value can be very useful in error or complete messages or to display the old value overlays by a loading spinner
 * in case of the suspense state.
 *
 * If a notification of kind `next` enters and its value is undefined we turn it into a suspense notification
 * If a notification of kind `error`, `complete`, `suspense` enters we take the last value from of a next notification
 * and assign it as new value to the notification
 */
const handleSuspenseAndLastValueInNotifications = () => {
    // Used to store the last value per handleSuspenseAndLastValueInNotifications call
    let latestNextValue;
    // returns a projection function with a lastValue cache
    return (notification) => {
        // if it is the notification is of type next we take its value
        // otherwise we keep the existing last value
        if (notification.kind === "next" /* RxNotificationKind.Next */) {
            latestNextValue = notification.value;
        }
        // If a next notification enters with a value of undefined we turn it into a suspense notification
        if (notification.kind === "next" /* RxNotificationKind.Next */ &&
            notification.value === undefined) {
            return toRxSuspenseNotification(undefined);
        }
        // If a Notification of type error, complete or suspense enters we assign the latest last value to them.
        // This is needed to access the old value in case of error or complete.
        // Next notifications will pass as they are.
        if (notification.kind === "error" /* RxNotificationKind.Error */ ||
            notification.kind === "complete" /* RxNotificationKind.Complete */ ||
            notification.kind === "suspense" /* RxNotificationKind.Suspense */) {
            notification.value = latestNextValue;
        }
        return notification;
    };
};
/**
 * @internal
 *
 * @description
 * This factory function returns an object that can be driven imperatively over a `next` method.
 * Internally it prepares the incoming values for rendering by turning them into "template notifications",
 * an extended `ObservableNotification` object used to determine the respective template for values, errors, completing
 *   or suspense states.
 *
 * Internally it handles different edge cases for initial emits. This helps to have or template creation lazy.
 * Also it maps any Observable to RxNotifications. These notifications are bound to the view later and handle the
 *   display of the default template as well as the suspense, error, complete templates.
 */
function createTemplateNotifier() {
    // A Subject driven from the outside, it can contain Observables, static values null and undefined on purpose of from unassigned properties
    const observablesSubject = new ReplaySubject(1);
    let emittedValueOnce = false;
    const values$ = observablesSubject.pipe(distinctUntilChanged(), 
    // handle static values inc null assignment and new Observable or Promises
    map((observable$) => {
        if (isObservableInput(observable$)) {
            return skipSuspenseIfHasValue(observable$);
        }
        else if (!emittedValueOnce && observable$ === undefined) {
            return NEVER;
        }
        return emitAndDontComplete(observable$);
    }), switchMap((o) => {
        return o.pipe(tap(() => (emittedValueOnce = true)), distinctUntilChanged(), rxMaterialize(), map(handleSuspenseAndLastValueInNotifications()));
    }));
    return {
        next(observable) {
            observablesSubject.next(observable);
        },
        withInitialSuspense(withInitialSuspense) {
            emittedValueOnce = emittedValueOnce || withInitialSuspense;
        },
        values$,
    };
    /**
     * @description
     * returns an observable that starts with an undefined value in case the input
     * observable$ does not emit a value immediately.
     * This is needed in order to skip the suspense template when we already know
     * there will be a next template rendered afterwards
     * @param observable$
     */
    function skipSuspenseIfHasValue(observable$) {
        return new Observable((subscriber) => {
            let startWithUndefined = true;
            const inner = from(observable$).subscribe({
                next: (v) => {
                    startWithUndefined = false;
                    subscriber.next(v);
                },
                error: (e) => {
                    startWithUndefined = false;
                    subscriber.error(e);
                },
                complete: () => subscriber.complete(),
            });
            if (emittedValueOnce && startWithUndefined) {
                subscriber.next(undefined);
            }
            return () => {
                inner.unsubscribe();
            };
        });
    }
}
function isObservableInput(input) {
    return (typeof input?.then === 'function' || isObservable(input));
}

/**
 * Generated bundle index. Do not edit.
 */

export { createTemplateNotifier, rxMaterialize, templateTriggerHandling, toRxCompleteNotification, toRxErrorNotification, toRxSuspenseNotification };

